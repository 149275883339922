<template>
  <div>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Login form</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="password"
                  prepend-icon="mdi-lock"
                  label="Mot de passe"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                  @click:append="showPassword=!showPassword"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="doLogin">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    password: "",
    showPassword: false,
  }),
  methods: {
    async doLogin() {
      try {
        let loginResponse = await axios.post(`${this.apiURL}/connection/login`, {password: this.password}, {})
        this.checkConnectionState()
        this.fetchProductsRefsTitles()
        this.addMessageAxios({message: loginResponse.data})
        this.$router.replace({
          name: 'Facturation',
          query: { ...this.$route.query }
        })
      } catch(error) {
        //console.log('error while login -> ', error)
        this.addMessageAxios({message: error.response.data, isError: true})
        if( error.response.data.alreadyConnected) {
          this.fetchProductsRefsTitles()
          this.$router.replace({
            name: 'Facturation',
            query: { ...this.$route.query }
          })
        }
        
      }
    },
    ...mapMutations(['addMessageAxios', 'setLoginState']),
    ...mapActions(['fetchProductsRefsTitles', 'checkConnectionState'])
  },
  computed: {
    ...mapGetters(['apiURL'])
  }
};
</script>

<style>
</style>